<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <router-link :to="{name: 'new-pages'}">
        <b-img
          src="@/assets/images/logo/logo-p2-cargo.webp"
          height="35px"
        />
      </router-link>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">

      <div class="d-flex align-items-center">
        <span
          v-for="(item, index) in menuItem.filter(ele => ele.inbox > 0)"
          :key="index"
          class="item-noti mx-50 d-none d-md-block"
        >
          <router-link
            :to="{ name: item.to, query: item.query }"
          >
            <span class="notifys-badge pulse">
              {{ item.inbox }}
            </span>
            <img
              :src="`/payment/${item.img}`"
              alt="member code"
              class="mx-25"
              height="20"
            >
          </router-link>
        </span>
      </div>

      <!-- <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link d-block d-md-none"
        class="dropdown-user"
      >
        <template #button-content>
          <b-avatar
            size="32"
            variant="light-primary"
            :src="require('@/assets/images/avatars/13-small.png')"
          />
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bold mb-0">
              John Doe
            </p>
          </div>
        </template> -->

      <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item> -->

      <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item> -->
      <!-- </b-nav-item-dropdown> -->

      <b-nav-item-dropdown
        ref="dropdown"
        right
        class="dropdown-notification mx-1 mr-25 d-block d-md-none"
        menu-class="dropdown-menu-media"
      >
        <template
          v-if="menuItem.length"
          #button-content
        >
          <feather-icon
            :badge="
              Commas(menuItem.reduce((total, item) => total + Number(item.inbox), 0))
            "
            badge-classes="bg-danger"
            class="text-body"
            icon="GridIcon"
            size="21"
          />
        </template>

        <!-- Header -->
        <div class="bg-notify">
          <div class="noti-detail">
            <h3 class="text-white">
              Notifications
            </h3>
          </div>
        </div>
        <div
          class="row m-0"
          style="overflow-y: scroll;"
        >
          <span
            v-for="(item, index) in menuItem.filter(ele => ele.inbox > 0)"
            :key="index"
            class="col-4"
          >
            <b-dropdown-item
              :to="{ name: item.to, query: item.query }"
              class="text-center"
              style="display: block;"
            >
              <div class="box-item-noti item-noti-1">
                <div class="box-item-noti-number">
                  {{ item.inbox }}
                </div>
                <i :class="`${item.icon} fa-2x text-white`" />
                <img
                  :src="`/payment/${item.img}`"
                  alt="member code"
                  height="25"
                >
              </div>
              <p class="mb-0 text-dark font-weight-b">
                {{ item.title }}
              </p>
              <div
                class="mb-0 text-dark mt-n25"
                style="word-wrap: break-word; font-size: 10px"
              >
                {{ item.detail }}
              </div>
            </b-dropdown-item>

          </span>
        </div>

        <!-- <div class="bg-notify m-0">
          <div class="noti-detail">
            <span class="bg-noti-detail">
              Notify All
            </span>
          </div>
        </div> -->
      </b-nav-item-dropdown>

      <!-- <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <feather-icon
            badge="6"
            badge-classes="bg-danger"
            class="text-body"
            icon="BellIcon"
            size="21"
          />
        </template>

        Header
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">
              Notificationss
            </h4>
            <b-badge
              pill
              variant="light-primary"
            >
              6 New
            </b-badge>
          </div>
        </li>

        Notifications
        <vue-perfect-scrollbar
          v-once
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
        >
          Account Notification
          <b-link
            v-for="notification in notifications"
            :key="notification.subtitle"
          >
            <b-media>
              <template #aside>
                <b-avatar
                  size="32"
                  :src="notification.avatar"
                  :text="notification.avatar"
                  :variant="notification.type"
                />
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notification.title }}
                </span>
              </p>
              <small class="notification-text">{{
                notification.subtitle
              }}</small>
            </b-media>
          </b-link>

          System Notification Toggler
          <div class="media d-flex align-items-center">
            <h6 class="font-weight-bolder mr-auto mb-0">
              System Notifications
            </h6>
            <b-form-checkbox
              :checked="true"
              switch
            />
          </div>

          System Notifications
          <b-link
            v-for="notification in systemNotifications"
            :key="notification.subtitle"
          >
            <b-media>
              <template #aside>
                <b-avatar
                  size="32"
                  :variant="notification.type"
                >
                  <feather-icon :icon="notification.icon" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notification.title }}
                </span>
              </p>
              <small class="notification-text">{{
                notification.subtitle
              }}</small>
            </b-media>
          </b-link>
        </vue-perfect-scrollbar>

        Cart Footer
        <li class="dropdown-menu-footer">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
          >Read all notifications</b-button>
        </li>
      </b-nav-item-dropdown> -->

      <router-link
        :to="{ name: 'cart' }"
        class="mx-50"
        style="margin-right: 10px"
      >
        <feather-icon
          :badge="CartLength"
          badge-classes="bg-danger"
          class="text-body"
          icon="ShoppingCartIcon"
          size="21"
        />
      </router-link>

      <div class="account-info-box d-md-block d-none ml-1 p-0">
        <div class="account-info">
          <b-avatar
            size="32"
            variant="light-primary"
            :src="require('@/assets/images/logo/logo-p2.webp')"
          />
          <span class="account-name">{{ userData.username }}</span>
        </div>
      </div>

      <b-dropdown
        no-caret
        variant="link"
        size="sm"
        class="mt-50"
      >
        <template #button-content>
          <span
            class="text-dark"
          ><i class="far fa-globe-americas" />
            {{ selectedLang || "ไทย" }}</span>
        </template>
        <b-dropdown-item
          value="th"
          @click="updateSelectedText('ไทย', 'th')"
        ><img
          src="/thai.png"
          class="flag-icon"
        > ไทย</b-dropdown-item>
        <b-dropdown-item
          value="en"
          @click="updateSelectedText('English', 'en')"
        ><img
          src="/eng.png"
          class="flag-icon"
        > English</b-dropdown-item>
        <b-dropdown-item
          value="cn"
          @click="updateSelectedText('中国', 'cn')"
        ><img
          src="/china.png"
          class="flag-icon"
        > 中国</b-dropdown-item>
      </b-dropdown>
      <a
        class="education-hub education mx-25"
        @click="logout()"
      >
        {{ $t("logout") }}
      </a>
    </b-navbar-nav>
  </div>
</template>
<!-- แก้ที่นี่-->
<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
  BMedia,
  BButton,
  BFormCheckbox,
  BBadge,
  BDropdown,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/no-extraneous-dependencies
import Ws from '@adonisjs/websocket-client'

const data = { soundurl: '/mp3/good_notification.mp3' }

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    VuePerfectScrollbar,
    BMedia,
    BButton,
    BFormCheckbox,
    BBadge,
    BDropdown,
    // Navbar Components
    // DarkToggler,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      locale: this.$i18n.locale,
      CartLength: 0,
      items: [],
      selectedLang: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      CartTotal: null,
      ws: null,
    }
  },
  computed: {
    menuItem() {
      const checkitem = []
      if (this.userData.ability.some(ele => ele.subject === 'admin-order')) {
        checkitem.push({
          key: 'ord1',
          title: 'ORD-1',
          detail: 'การสั่งซื้อ-1',
          img: 'CART.svg',
          to: 'admin-order',
          query: { querykey: 1 },
          class: 'item-noti',
          inbox: localStorage.getItem('ord1') ? localStorage.getItem('ord1') : 0,
        },
        {
          key: 'ord2',
          title: 'ORD-2',
          detail: 'การสั่งซื้อ-2',
          img: 'ORD-2.svg',
          to: 'admin-order',
          query: { querykey: 2 },
          class: 'item-noti-2',
          inbox: localStorage.getItem('ord2') ? localStorage.getItem('ord2') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-receipt')) {
        checkitem.push({
          key: 'receipt',
          title: 'RECEIPT',
          detail: 'ใบเสร็จ',
          img: 'receipt.svg',
          to: 'admin-finance-receipt',
          query: { querykey: 3 },
          class: 'item-noti-6',
          inbox: localStorage.getItem('receipt') ? localStorage.getItem('receipt') : 0,
        })
        // , {
        //   key: 'receipt_not',
        //   title: 'RECEIPT',
        //   detail: 'ใบเสร็จไม่ได้พิมพ์',
        //   img: 'receipt.svg',
        //   to: 'admin-finance-receipt',
        //   query: { querykey: 1 },
        //   class: 'item-noti-6',
        //   inbox: localStorage.getItem('receipt_not') ? localStorage.getItem('receipt_not') : 0,
        // }
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-transfer')) {
        checkitem.push({
          key: 'transfer_cn',
          title: 'TRANSFER CN',
          detail: 'โอนเงินไปจีน',
          img: 'PAY.svg',
          to: 'admin-transfer',
          query: { querykey: 2 },
          class: 'item-noti-5',
          inbox: localStorage.getItem('transfer_cn') ? localStorage.getItem('transfer_cn') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-productfailed')) {
        checkitem.push({
          key: 'los',
          title: 'LOS',
          detail: 'แจ้งสินค้าไม่ได้',
          img: 'LOS.svg',
          to: 'admin-lost-package',
          query: { querykey: 2 },
          class: 'item-noti-7',
          inbox: localStorage.getItem('los') ? localStorage.getItem('los') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-export')) {
        checkitem.push({
          key: 'send',
          title: 'SEND',
          detail: 'รายการค้างส่งออก',
          img: 'shipping-type.svg',
          to: 'admin-export',
          query: { querykey: 1 },
          class: 'item-noti-9',
          inbox: localStorage.getItem('send') ? localStorage.getItem('send') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-pay-importfee')) {
        checkitem.push({
          key: 'nobill',
          title: 'NO BILLING',
          detail: 'ยังไม่ได้ชำระเงิน',
          img: 'order_no.svg',
          to: 'admin-payment',
          query: { querykey: 1 },
          class: 'item-noti-10',
          inbox: localStorage.getItem('nobill') ? localStorage.getItem('nobill') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-pay-importfee')) {
        checkitem.push({
          key: 'Payments',
          title: 'PAYMENT',
          detail: 'ตรวจสอบค่าขนส่งสินค้า',
          img: 'Payimport.svg',
          to: 'admin-payment',
          query: { querykey: 2 },
          class: 'item-noti-11',
          inbox: localStorage.getItem('Payments') ? localStorage.getItem('Payments') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-withdraw')) {
        checkitem.push({
          key: 'Withdraw',
          title: 'WITHDRAW',
          detail: 'ถอนเงิน',
          img: 'withdraw.svg',
          to: 'admin-finance-withdraw',
          query: { querykey: 2 },
          class: 'item-noti-14',
          inbox: localStorage.getItem('Withdraw') ? localStorage.getItem('Withdraw') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-refill')) {
        checkitem.push({
          key: 'Refill',
          title: 'DEPOSIT',
          detail: 'เติมเงิน',
          img: 'deposit.svg',
          to: 'admin-refill',
          class: 'item-noti-15',
          inbox: localStorage.getItem('Refill') ? localStorage.getItem('Refill') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-alipay')) {
        checkitem.push({
          key: 'Alipay',
          title: 'ALIPAY',
          detail: 'เติมเงินอาลีเพย์',
          img: 'alipay.svg',
          to: 'admin-china-alipay',
          query: { querykey: 2 },
          class: 'item-noti-16',
          inbox: localStorage.getItem('Alipay') ? localStorage.getItem('Alipay') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-payfor')) {
        checkitem.push({
          key: 'Payforchina',
          title: 'TAO',
          detail: 'ฝากจ่ายเถาเปา',
          img: 'dstaobao.svg',
          to: 'admin-china-payfor',
          query: { querykey: 1 },
          class: 'item-noti-17',
          inbox: localStorage.getItem('Payforchina') ? localStorage.getItem('Payforchina') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-payfor')) {
        checkitem.push({
          key: 'refund',
          title: 'CBSHOP',
          detail: 'ร้านค้าคืนเงิน',
          img: 'cashback.svg',
          to: 'admin-china-payfor',
          query: { querykey: 2 },
          class: 'item-noti-18',
          inbox: localStorage.getItem('refund') ? localStorage.getItem('refund') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-ownerlessproduct')) {
        checkitem.push({
          key: 'goodsconfirm',
          title: 'GCONF',
          detail: 'สินค้าไม่มีเจ้าของ',
          img: 'order.svg',
          to: 'admin-goodsconfirm',
          query: { querykey: 2 },
          class: 'item-noti-19',
          inbox: localStorage.getItem('goodsconfirm') ? localStorage.getItem('goodsconfirm') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-point-withdraw')) {
        checkitem.push({
          key: 'point',
          title: 'WPOINT',
          detail: 'แลกแต้ม',
          img: 'point.svg',
          to: 'admin-point-withdraw',
          query: { querykey: 2 },
          class: 'item-noti-20',
          inbox: localStorage.getItem('point') ? localStorage.getItem('point') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-verifyInfomation')) {
        checkitem.push({
          key: 'verifyInfomation',
          title: 'INFOMATION',
          detail: 'ยังไม่ได้ยืนยันข้อมูลส่วนบุคคล',
          img: 'verify.svg',
          to: 'admin-verifyInfomation',
          query: { querykey: 1 },
          class: 'item-noti-12',
          inbox: localStorage.getItem('verifyInfomation') ? localStorage.getItem('verifyInfomation') : 0,
        })
      }
      if (this.userData.ability.some(ele => ele.subject === 'admin-verifyAccount')) {
        checkitem.push({
          key: 'verifyAccount',
          title: 'ACCOUNT',
          detail: 'ยังไม่ได้ยืนยันข้อมูลบัญชี',
          img: 'bank.svg',
          to: 'admin-verifyAccount',
          query: { querykey: 1 },
          class: 'item-noti-13',
          inbox: localStorage.getItem('verifyAccount') ? localStorage.getItem('verifyAccount') : 0,
        })
      }
      return checkitem
      // [
      // {
      //   key: 'ord1',
      //   title: 'ORD-1',
      //   detail: 'การสั่งซื้อ-1',
      //   img: 'CART.svg',
      //   to: 'admin-order',
      //   query: { querykey: 1 },
      //   class: 'item-noti',
      //   inbox: 0,
      // },
      // {
      //   key: 'ord2',
      //   title: 'ORD-2',
      //   detail: 'การสั่งซื้อ-2',
      //   img: 'ORD-2.svg',
      //   to: 'admin-order',
      //   query: { querykey: 2 },
      //   class: 'item-noti-2',
      //   inbox: 0,
      // },
      // {
      //   key: 'receipt',
      //   title: 'RECEIPT',
      //   detail: 'ใบเสร็จ',
      //   img: 'receipt.svg',
      //   to: 'admin-finance-receipt',
      //   query: { querykey: 3 },
      //   class: 'item-noti-6',
      //   inbox: 0,
      // },
      // {
      //   key: 'receipt_not',
      //   title: 'RECEIPT',
      //   detail: 'ใบเสร็จไม่ได้พิมพ์',
      //   img: 'receipt.svg',
      //   to: 'admin-finance-receipt',
      //   query: { querykey: 1 },
      //   class: 'item-noti-6',
      //   inbox: 0,
      // },
      // {
      //   key: 'transfer_cn',
      //   title: 'TRANSFER CN',
      //   detail: 'โอนเงินไปจีน',
      //   img: 'PAY.svg',
      //   to: 'admin-transfer',
      //   query: { querykey: 2 },
      //   class: 'item-noti-5',
      //   inbox: 0,
      // },
      // {
      //   key: 'los',
      //   title: 'LOS',
      //   detail: 'แจ้งสินค้าไม่ได้',
      //   img: 'LOS.svg',
      //   to: 'admin-lost-package',
      //   query: { querykey: 2 },
      //   class: 'item-noti-7',
      //   inbox: 0,
      // },
      // {
      //   key: 'send',
      //   title: 'SEND',
      //   detail: 'รายการค้างส่งออก',
      //   img: 'shipping-type.svg',
      //   to: 'admin-export',
      //   query: { querykey: 1 },
      //   class: 'item-noti-9',
      //   inbox: 0,
      // },
      // {
      //   key: 'nobill',
      //   title: 'NO BILLING',
      //   detail: 'ยังไม่ได้ชำระเงิน',
      //   img: 'order_no.svg',
      //   to: 'admin-payment',
      //   query: { querykey: 1 },
      //   class: 'item-noti-10',
      //   inbox: 0,
      // },
      // {
      //   key: 'check_delivery', title: 'CHECK DELIVERY ', detail: 'เช็คสถานะและแจ้งส่งของ', img: 'ORD-1.svg', to: 'dashboard', class: 'item-noti-1', inbox: 0,
      // },

      // {
      //   title: 'REF', detail: 'เติมเงินเข้าระบบ', img: 'refill.svg', to: 'dashboard', class: 'item-noti-3', inbox: 4,
      // },
      // {
      //   title: 'POINT', detail: 'การสั่งซื้อ-1', img: 'receipt.svg', to: 'dashboard', class: 'item-noti-4', inbox: 5,
      // },
      // {
      //   title: 'FINANCE', detail: 'ถอนเงินออกจากระบบ', img: 'coupon.svg', to: 'dashboard', class: 'item-noti-8', inbox: 9,
      // },
      // ]
    },
    BaseWsUrl() {
      return this.$store.state.app.BaseWsUrl
    },
    WsMain() {
      return this.$store.state.app.WsMain
    },
    CartWs() {
      return this.$store.state.app.WsCart
    },
    WsNotiAgent() {
      return this.$store.state.app.WsNotiAgent
    },
    WsNotiSound() {
      return this.$store.state.app.WsNotiSound
    },
  },
  // watch: {
  //   locale(val) {
  //     this.$i18n.locale = val
  //     console.log(val)
  //   },
  // },

  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Congratulation Sam 🎉',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success',
      },
      {
        title: 'New message received',
        avatar: require('@/assets/images/avatars/9-small.png'),
        subtitle: 'You have 10 unread messages',
        type: 'light-info',
      },
      {
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger',
      },
    ]
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
  created() {
    // this.interval = setInterval(() => {
    //   // this.getUserslist()
    //   this.GetMycart()
    // }, 5000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    // this.GetMycart()
    this.CheckWsConnect()
  },
  methods: {
    closeDropdown() {
      // eslint-disable-next-line no-underscore-dangle
      this._okToHide = true
      this.$refs.dropdown.hide()
    },

    onEditControlMenuHide(bvEvent) {
      // eslint-disable-next-line no-underscore-dangle
      if (this._okToHide) {
        console.log('hiding')
        // eslint-disable-next-line no-underscore-dangle
        this._okToHide = false
      } else {
        console.log('prevent hide')
        bvEvent.preventDefault()
      }
    },
    updateSelectedText(text, lang) {
      this.selectedLang = text
      this.$i18n.locale = lang
    },
    async CheckWsConnect() {
      if (!this.WsMain) {
        await this.ConnectWS()
      }
      if (!this.CartWs) {
        await this.SubWsOnCart()
        this.WsOnCart()
      } else {
        this.WsOnCart()
      }
      if (!this.WsNotiAgent) {
        await this.SubWsOnNoti()
        this.WsOnNoti()
      } else {
        this.WsOnNoti()
      }
      if (!this.WsNotiSound) {
        await this.SubWsOnNotiSound()
        this.WsOnSound()
      } else {
        this.WsOnSound()
      }
    },
    ConnectWS() {
      const Token = localStorage.getItem('token')
      if (Token) {
        const ws = Ws(this.BaseWsUrl)
        ws.withJwtToken(Token).connect()

        ws.on('open', () => {
          console.log('Connection established')
        })
        ws.on('error', error => {
          console.log('Connection Error', error)
        })

        ws.on('close', event => {
          console.log('Connection closed', event)
        })
        this.$store.commit('app/SET_WSMAIN', ws)
      }
    },
    SubWsOnCart() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsCart = this.WsMain.subscribe(`cart:${UserData.userID}`)
      this.$store.commit('app/SET_WSCART', WsCart)
    },
    SubWsOnNoti() {
      // console.debug('SubWsOnNoti')
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsNotiAgent = this.WsMain.subscribe(`noti:${UserData.agent_id}`)
      this.$store.commit('app/SET_WSNOTIAG', WsNotiAgent)
    },
    SubWsOnNotiSound() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsNoti = this.WsMain.subscribe(`NotifySound:${UserData.agent_id}`)
      this.$store.commit('app/SET_WSNOTISOUND', WsNoti)
    },
    WsOnCart() {
      this.CartWs.on('open', () => {
        console.log('CartWs established')
      })
      this.CartWs.emit('cart', '')
      this.CartWs.on('cart', message => {
        this.CartLength = message
      })
      this.CartWs.on('error', error => {
        console.log('Cart Error', error)
      })
    },
    async WsOnSound() {
      this.WsNotiSound.on('open', () => {
        console.log('Sound established')
      })
      // eslint-disable-next-line no-restricted-syntax

      this.WsNotiSound.on('message', message => {
        if (message.status) {
          const checkrole = this.userData.ability.find(ele => ele.subject === message.subject)
          if (checkrole) {
            const audio = new Audio(data.soundurl)
            audio.play()
          }
        }
      })

      this.WsNotiSound.on('error', error => {
        console.log('WsNoti Error', error)
      })
    },
    recheck_noti() {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.menuItem) {
        item.inbox = localStorage.getItem(item.key) ? localStorage.getItem(item.key) : 0
      }
    },
    async WsOnNoti() {
      // console.debug('WsOnNoti')
      this.WsNotiAgent.on('open', () => {
        console.debug('WsNoti established')
      })
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.menuItem) {
        // console.debug('WsOnNoti', item.key)
        this.WsNotiAgent.on(item.key, message => {
          // item.inbox = message || 0
          localStorage.setItem(item.key, message || 0)
          this.recheck_noti()
          // if (Number(item.inbox) === 0) {
          //   const index = this.menuItem.findIndex(ele => ele === item)
          //   if (index !== -1) {
          //     this.menuItem.splice(index, 1)
          //   }
          // }
        })
      }

      this.WsNotiAgent.on('error', error => {
        console.log('WsNoti Error', error)
      })
    },
    GetMycart() {
      this.$http
        .get('/cart/mycart')
        .then(response => {
          this.items = response.data
          const Shop = response.data.shops
          if (Shop && Shop.length > 0) {
            this.CartLength = Shop.reduce(
              (acc, ele) => acc + ele.products.length,
              0,
            )
          }
        })
        .catch(error => console.log(error))
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
      this.WsMain.close()
      this.$store.commit('app/SET_WSMAIN', null)
      this.$store.commit('app/SET_WSCART', null)
      this.$store.commit('app/SET_WSCHAT', null)
      this.$store.commit('app/SET_WSRUNTRACK', null)
      this.$store.commit('app/SET_WSNOTISOUND', null)
      this.$store.commit('app/SET_WSNOTIAG', null)
    },
  },
}
</script>
<style scoped>
.dropdown-menu .dropdown-item {
    padding: 0 !important;
}
</style>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
.dropdown-as-text {
  background-color: transparent;
  border: none;
}

.flag-icon {
  width: 20px; /* หรือขนาดที่ต้องการ */
  height: auto;
  margin-right: 5px;
}
</style>
